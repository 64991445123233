<template>
  <div :class="$style['pt-invoice']">
    <validate-form tag="div" ref="form">
      <pt-form-item>
        <div :class="$style['form-item-box']">
          <span>发票抬头：</span>
          <p>
            {{$route.query.title}}
          </p>
        </div>
      </pt-form-item>
      <pt-form-item>
        <div :class="$style['form-item-box']">
          <span>纳税人识别号：</span>
          <p>
            {{$route.query.taxId}}
          </p>
        </div>
      </pt-form-item>
      <pt-form-item>
        <div :class="$style['form-item-box']">
          <span>开票内容：</span>
          <p>
            {{$route.query.invoiceContent}}
          </p>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="上传发票照片"
        ref="username"
      >
        <div :class="[$style['form-item-box']]">
          <span style="margin-top: 10px;">上传发票照片：</span>
          <cube-upload
          ref="upload"
          :action="action"
          v-model="formValue.attachments" 
          />
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="快递单号"
        ref="expressCode"
      >
        <div :class="$style['form-item-box']">
          <span>快递单号：</span>
          <pt-array-input v-model="formValue.expressCode" placeholder="请填写快递单号">
          </pt-array-input>
        </div>
      </pt-form-item>
    </validate-form>
    <div :class="$style['btn-box']">
      <wt-button :class="$style['submit-btn']" @click="submit" primary>确认开票</wt-button>
    </div>
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-invoice'

export default {
  name: 'pt-invoice',
  computed: {
    classes () {
      return `${prefixCls}`
    }
  },
  data () {
    return {
      action:{
        withCredentials:true,
        target:this.$uploadApi,
        data:{
          objectId: this.$route.query?.taxId || 0,
          resourceName: 'invoice'
        }
      },
      privateResourcesUrl: this.$privateResourcesUrl,
      formValue: {}
    }
  },
  methods:{
    submit () {
      this.$refs.form.validate("formValue").then((valid) => {
        console.log('---valid',valid)
        if(!valid){
          return
        }
        let data = {
          ids: [this.$route.params.id],
          taxId: this.$route.query.taxId,
          title: this.$route.query.title,
          ...this.form,
          attachments: this.formValue.attachments?.map(item => {
            return {
              type: 'INVOICE_PICTURE',
              name: item.name,
              ossUrl: item.response
            }
          })
        }
        this.$loading()
        this.$store.dispatch('handleInvoice', data).then(res => {
          this.$toast.success('操作成功')
          this.$router.go(-1)
        }).catch(err => {
          this.$toast.err(err || '操作失败')
        }).finally(()=>{
          this.$loading.hide()
        })
      });
      
    
    },
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-invoice{
  > div:first-child{
    background-color: #fff;
    padding:@page-padding 25px;
    >div{
      &:last-child{
        .form-item-box{
          border-bottom: none;
        }
      }
    }
  }
  .btn-box{
    padding: 20px @page-padding;
    :global{
      .cube-btn{
        width: 100%;
      }
    }
  }
}
.form-item-box{
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid @page-background-color;
  &.required{
    > span{
      position: relative;
      &::before{
        content: '*';
        position: absolute;
        color:red;
        left: -10px;
        top: 0;
        width: auto;
      }
    }
  }
  > span{
    white-space: nowrap;
    width: 120px;
    text-align: left;
    margin-top: 10px;
  }
  > p{
    padding: 10px;
  }
  :global{
    .wt-input,
    .cube-select{
      flex: 1;
      text-align: left;
      &::after{
        border: none;
      }
    }
  }
}
</style>
