<template>
  <pt-invoice class="pt-invoice__withData"></pt-invoice>
</template>

<script>
import PtInvoice from './index'
export default {
  name: 'pt-invoice__withData',
  components: {
    'pt-invoice': PtInvoice
  }
}
</script>
