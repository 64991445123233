<template>
  <pt-invoice class="pt-invoice__page"></pt-invoice>
</template>

<script>
import PtInvoice from 'cps/trust-order/pt-invoice/withData'
export default {
  name: 'pt-invoice__view',
  components: {
    'pt-invoice': PtInvoice
  }
}
</script>
